<template>
<div>
  <div class="vx-row" v-if="item">
    <div class="vx-col w-full lg:w-1/1">
      <!-- ABOUT CARD -->
      <vx-card :title="`Detail ${$i18n.t('Quotation')}`" class="mt-base">
        <!-- ACTION SLOT -->
        <template slot="actions">
          <div class="flex">
            <vs-button color="primary" icon-pack="feather" icon="icon-edit" @click="$router.push({ name: 'quotation-edit', params: { id: $route.params.id } })" class="mr-4">
              {{ $i18n.t('Edit') }}
            </vs-button>

            <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'quotation' })">
              {{ $i18n.t('Back') }}
            </vs-button>
          </div>
        </template>

        <div class="vx-row">
          <div class="vx-col w-full lg:w-1/2" v-for="(cell, i) in cells" :key="i">
            <div class="mt-5">
              <h6 class="font-bold">{{ cell.header }}</h6>
              <p>{{ item[cell.field] }}</p>
            </div>
          </div>
        </div>


      </vx-card>
    </div>
  </div>
</div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "QuotationDetail",
  data: () => ({

  }),
  computed: {
    ...mapGetters({
      item: 'quotation/showItem',
    }),
    cells () {
      return [
        {
          header: this.$i18n.t('CustomerName'),
          field: 'customer'
        },
        {
          header: this.$i18n.t('Description'),
          field: 'deskripsi'
        },
        {
          header: this.$i18n.t('QuotationNumber'),
          field: 'nomor'
        },
        {
          header: this.$i18n.t('Sales'),
          field: 'sales'
        },
        {
          header: this.$i18n.t('Date'),
          field: 'tanggal_display'
        },
      ]
    }
  },
  created () {
    this.id = this.$secure.decrypt(this.$route.params.id);
    this.$store.dispatch('quotation/detailItem', {id: this.id}).then(res => {
      console.log(res);
    });
  }
}
</script>

<style scoped>

</style>
